<template>
<v-card flat class="mt-5">
    <v-card-text>
        <div id="app">

            <v-snackbar v-model="snackbartest.visible" auto-height :color="snackbartest.color" :multi-line="snackbartest.mode === 'multi-line'" :timeout="snackbartest.timeout" :top="snackbartest.position === 'top'">
                <v-layout align-center pr-4>
                    <v-icon class="pr-3" dark large>{{ snackbartest.icon }}</v-icon>
                    <v-layout column>
                        <div>
                            <strong>{{ snackbartest.title }}</strong>
                        </div>
                        <div>{{ snackbartest.text }}</div>
                    </v-layout>
                </v-layout>
                <v-btn v-if="snackbartest.timeout === 0" icon @click="snackbartest.visible = false">
                    <v-icon>clear</v-icon>
                </v-btn>
            </v-snackbar>

            <v-data-table :headers="headers" :items="list" sort-by="id" class="elevation-1">
                
                <template v-slot:top>
                    <v-toolbar flat>
                        <v-toolbar-title>Liste des projets </v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-spacer></v-spacer>
                        <v-dialog v-model="dialog" max-width="650px">

                            <v-snackbar v-model="snackbar" :multi-line="multiLine">
                                {{ text }}

                                <template v-slot:action="{ attrs }">
                                    <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
                                        Fermer
                                    </v-btn>
                                </template>
                            </v-snackbar>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="green" dark class="mb-2" v-bind="attrs" v-on="on" @click="nouveau()">
                                    Excel
                                </v-btn>

                            </template>

                            <v-card>
                                <v-card-title>
                                    <span class="text-h5">{{ formTitle }}</span>
                                </v-card-title>

                                <v-card-text>
                                    <v-container>
                                        <v-form ref="form" v-model="valid" lazy-validation>
                                            <v-select :items="listpays" v-model="selectpays" dense item-value="id" :rules="[v => !!v || 'Veuillez selectionnez le pays']" required outlined label="Liste des pays">
                                                <template slot="selection" slot-scope="data">
                                                    {{ data.item.code_pays }} - {{ data.item.libelle }}
                                                </template>
                                                <template slot="item" slot-scope="data">
                                                    {{ data.item.code_pays }} - {{ data.item.libelle }}
                                                </template>
                                            </v-select>
                                            <v-select :items="listcible" v-model="selectcible" dense item-value="id" @change="CibleSearch()" :rules="[v => !!v || 'Veuillez selectionnez les cibles des odds']" required outlined label="Liste des cibles">
                                                <template slot="selection" slot-scope="data">
                                                    {{ data.item.code }} - {{ data.item.description }}
                                                </template>
                                                <template slot="item" slot-scope="data">
                                                    {{ data.item.code }} - {{ data.item.description }}
                                                </template>
                                            </v-select>
                                            <v-select :items="listmethode" v-model="selectemethode" dense item-value="id" @change="selectypemethode()" outlined label="Choix de la methode">
                                                <template slot="selection" slot-scope="data">
                                                    {{ data.item.libellemethode }}
                                                </template>
                                                <template slot="item" slot-scope="data">
                                                    {{ data.item.libellemethode }}
                                                </template>
                                            </v-select>
                                            <v-select :items="listindicateur" v-model="selecteindicateur" dense item-value="id" :disabled="para_indicateur" @change="IndicateurSearch_only()" outlined label="Liste des indicateurs">
                                                <template slot="selection" slot-scope="data">
                                                    {{ data.item.code }} - {{ data.item.libelle }}
                                                </template>
                                                <template slot="item" slot-scope="data">
                                                    {{ data.item.code }} - {{ data.item.libelle }}
                                                </template>
                                            </v-select>
                                            <v-text-field v-model="code" label="Indicateur CODE" dense outlined :disabled="para_code"></v-text-field>
                                            <v-textarea v-model="libelle" label="Reformulation de l'indicateur au niveau du pays" dense outlined required></v-textarea>
                                            <v-textarea v-model="libelle_definition" label="définition de l'indicateur" dense outlined required></v-textarea>

                                        </v-form>

                                    </v-container>
                                </v-card-text>

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blue darken-1" text @click="dialog=false">
                                        Fermer
                                    </v-btn>
                                    <v-btn color="blue darken-1" text @click="save()">
                                        Enregistrer
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-dialog v-model="dialogDelete" max-width="500px">
                            <v-card>
                                <v-card-title class="text-h5">Voullez vous vraiment supprimer?</v-card-title>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blue darken-1" text @click="dialogDelete=false">Annuller</v-btn>
                                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">Confirmer</v-btn>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>

               

                <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon color="#F9A825" v-on="on" large  class="mr-2" @click="editItem(item)">
                        mdi-pencil
                    </v-icon>
           </template>
           <span>Modifier</span>
        </v-tooltip>
        <v-tooltip top>
        <template v-slot:activator="{ on }">
            <v-icon color="#01579B" v-on="on" large class="mr-2" @click="collecte_data(item)">
                        mdi-text-box-plus
                    </v-icon>
                </template>
           <span>Collecte des données</span>
        </v-tooltip>
        <v-tooltip top>
        <template v-slot:activator="{ on }">
            <v-icon color="green" v-on="on" large class="mr-2" @click="editItem(item)">
                        mdi-eye
                    </v-icon>
                </template>
           <span>Consulte les détails des données</span>
        </v-tooltip>
                   
                
                  
                    
                   
                </template>
           
                
                <template v-slot:no-data>
                    <v-btn color="primary" @click="initialize()">
                        Actualiser la liste
                    </v-btn>
                </template>

                <template v-slot:[`item.createdby`]="{ item }">
                    {{ item.createdby.nom }} {{ item.createdby.prenom }}
                </template>

            </v-data-table>

        </div>

    </v-card-text>
</v-card>
</template>

  
<script>
import api from '../../../../serviceApi/apiService'

export default {
    props: {
        informationData: {
            type: Object,
            default: () => {},
        },
    },
    data: () => ({
        multiLine: true,
        snackbartest: false,
        textmessage: '',
       
        snackbar: false,
        snackbars: false,
       
        text: `message`,
        textsnackbar: 'messages',
       
        dialog: false,
        dialogDelete: false,
       
        headers: [{
                text: 'Actions',
                value: 'actions',
                sortable: false,
                divider: true,
                width: '180px'
            },
            {
                text: 'Code',
                value: 'id',
                divider: true,
                width: '10px'
            },
            {
                text: 'Editeur',
                value: 'createdby',
                divider: true,
                width: '100px'
            },
            {
                text: 'Etat',
                align: 'start',
                sortable: false,
                value: 'statut',
                divider: true,
                width: '60px'
            },
            {
                text: 'organisations',
                align: 'start',
                sortable: false,
                value: 'organisation.libelle_organisation',
                divider: true,
                width: '200px'
            },
            {
                text: 'Projets',
                align: 'start',
                sortable: false,
                value: 'libelle_projet',
                divider: true,
                width: '250px'
            },
            {
                text: 'Date de début',
                align: 'start',
                sortable: false,
                value: 'date_debut',
                divider: true,
                width: '250px'
            },

            {
                text: 'Date de fin',
                align: 'start',
                sortable: false,
                value: 'date_fin',
                divider: true,
                width: '200px'
            },
            {
                text: 'Directeur technique',
                align: 'start',
                sortable: false,
                value: 'directeur_technique_nom_prenom',
                divider: true,
                width: '100px'
            },

            {
                text: 'Téléphone Directeur technique',
                align: 'start',
                sortable: false,
                value: 'directeur_technique_telephone',
                divider: true,
                width: '100px'
            },

            {
                text: 'E-mail Directeur technique',
                align: 'start',
                sortable: false,
                value: 'directeur_technique_email',
                divider: true,
                width: '100px'
            },
            {
                text: 'Directeur Projet',
                align: 'start',
                sortable: false,
                value: 'responsable_projet_nom_prenom',
                divider: true,
                width: '100px'
            },
            {
                text: 'Téléphone Directeur Projet',
                align: 'start',
                sortable: false,
                value: 'responsable_projet_telephone',
                divider: true,
                width: '100px'
            },
            {
                text: 'E-mail Directeur Projet',
                align: 'start',
                sortable: false,
                value: 'responsable_projet_email',
                divider: true,
                width: '100px'
            },
            {
                text: 'Comptable',
                align: 'start',
                sortable: false,
                value: 'comptable_nom_prenom',
                divider: true,
                width: '100px'
            },

            {
                text: 'Téléphone Comptable',
                align: 'start',
                sortable: false,
                value: 'comptable_telephone',
                divider: true,
                width: '100px'
            },

            {
                text: 'E-mail Comptable',
                align: 'start',
                sortable: false,
                value: 'comptable_email',
                divider: true,
                width: '100px'
            },

        ],
        formTitle: 'Formulaire',
        desserts: [],
        list: [],
       
        editedIndex: -1,

    }),

    methods: {
        //=====Affichages  liste des odds==========
        readAll: async function () {
            let fd = new FormData();
            fd.append('id_organisations', '7add918e-08f0-4003-8199-08bd4fb06027');
            const data = await api.createUpdatedata('projet/list-projet-ong',fd);
            this.list = data;
           
        },

       

       
        deleteData: async function () {
            let fd = new FormData();
            fd.append('id', this.id_odds);
            const res = await api.createUpdatedata('indicateur-odd/delete', fd);
            if (res.status == 200) {

            }
            // this.messagesligne(res);
        },
        getdefiition(item) {
            if (item.definition == null) {
                return '';
            }
            return item.definition;
        },

        createUpdatedata: async function () {

            let fd = new FormData();
            fd.append('id_cibles', this.selectcible);
            fd.append('code', this.code);
            fd.append('libelle', this.libelle);
            fd.append('definition', this.libelle_definition);
            fd.append('id_indicateurs', this.selecteindicateur);
            fd.append('id_pays', this.selectpays);

            if (this.textetat) {

                const res = await api.createUpdatedata('indicateur-odd-extrat/update/' + this.id_odds, fd);
                if (res.status == 200) {
                    this.textmessage = res.message;
                    this.snackbartest = {
                        color: "green",
                        icon: "mdi-check-all",
                        mode: "multi-line",
                        position: "top",
                        timeout: 2500,
                        title: "Information",
                        text: this.textmessage,
                        visible: true
                    };
                    this.dialog = false;
                }

            } else {
                const res = await api.createUpdatedata('indicateur-odd-extrat/add', fd);
                if (res.status == 200) {
                    this.textmessage = res.message;
                    this.snackbartest = {
                        color: "green",
                        icon: "mdi-check-all",
                        mode: "multi-line",
                        position: "top",
                        timeout: 2500,
                        title: "Information",
                        text: this.textmessage,
                        visible: true
                    };

                }

            }
            this.clear();
            this.readAll();
        },
        nouveau() {
            this.clear();

        },

        detail_collecte(){
              //this.$emit('libelle_indicateur', "Indicateur : " + item.souscriptions.indicateurodd.code + "-" + item.souscriptions.indicateurodd.libelle);
            //  console.log('indicateur',item.souscriptions.indicateurodd_extrat.id);
         //   this.$router.push({ name: 'operation-collecte-odd', params: {idcollectes: item.id, id_indicateur_extrat: item.souscriptions.indicateurodd_extrat.id}});
          /*  this.$router.push('operation-collecte-odd',{
                params:{idcollectes:this.id},
            });*/
        },

        collecte_data(item){
            //this.$router.push({ name: 'autre-info-projets', params: {idprojet: item.id}});

             this.$router.push({path:'autre-info-projets/'+item.id,params:{idprojet:item.id}});
         
        },

        clear() {

            this.code = "";
            this.libelle = "";
            this.libelle_definition = "";
            this.selectcible = '0';
            this.selectpays = '0';
            this.selecteindicateur = '0';
            this.selectemethode = '0';

            this.textetat = false;
        },
        deleteItem(item) {
            this.id_odds = item.id;
            this.dialogDelete = true;

        },
        editItem(item) {
            this.id_odds = item.id;
            this.libelle = item.libelle;
            this.code = item.code;
            this.textetat = true;
            this.dialog = true;
        },

        deleteItemConfirm() {
            this.deleteData();
            this.readAll();
            this.dialogDelete = false
        },

        closeDelete() {
            this.dialogDelete = false
        },
        selectypemethode() {
            if (this.selectemethode == '1') {
                this.code = "";
                this.para_code = true;
                this.para_indicateur = false;
                this.selecteindicateur = '0';
            } else {
                this.code = "";
                this.selecteindicateur = '0';
                this.para_code = false;
                this.para_indicateur = true;
            }
        },

        save() {
            if (this.libelle == '' || this.code == '') {

                this.textmessage = 'Veuillez les remplirs les champs '
                this.snackbartest = {
                    color: "red",
                    icon: "mdi-alert",
                    mode: "multi-line",
                    position: "top",
                    timeout: 2500,
                    title: "Information",
                    text: this.textmessage,
                    visible: true
                };
                return;
            }

            this.createUpdatedata();
            this.readAll();
            if (this.textetat) {
                this.dialog = false;
            }
        },
        initialize() {
            this.readAll();
        },
        CibleSearch() {
            //selec
            this.code = "";
            this.libelle_definition = "";
            this.libelle = "";

            this.listindicateur = [];
            this.listindicateur = this.listindicateur3.filter(this.filtrerParID_Indicateur);
        },
        CibleSearch2() {

            this.list = [];
            this.list = this.list2.filter(this.filtrerParID_table2);
        },
        PaysSearch2() {

            this.list = [];
            this.list = this.list2.filter(this.filtrerParID_table3);
        },
        filtrerParID_table3: function (obj) {
            if (obj.id_pays == this.selectpays2) {
                return true;
            } else {
                return false;
            }
        },
        filtrerParID_table2: function (obj) {
            if (obj.id_cibles == this.selectcible2 && obj.id_pays == this.selectpays2) {
                return true;
            } else {
                return false;
            }
        },
        filtrerParID_Indicateur: function (obj) {
            if (obj.id_cibles == this.selectcible) {
                return true;
            } else {
                return false;
            }
        },

        filtrerParID_Indicateur_onlys: function (obj) {
            if (obj.id == this.selecteindicateur) {
                return true;
            } else {
                return false;
            }
        },

        IndicateurSearch_only() {
            // this.listindicateur = [];
            this.code = this.listindicateur3.filter(this.filtrerParID_Indicateur_onlys)[0].code;
        },

    },
    mounted() {
        this.readAll();
        },

}
</script>
